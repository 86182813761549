<template>
  <div class="productOverall">
    <!-- <div class="main-Title bgff"><h2>Close Rule List</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :inline="true" :model="filter" label-width="50px" size="mini">
          <el-form-item label="name" class="mb0" prop="name">
            <el-input v-model="filter.name" clearable></el-input>
          </el-form-item>
          <el-form-item class="mb0">
            <el-button type="primary" @click="getList(1)" :loading="loading.list">Search</el-button>
            <el-button type="primary" @click="add()">Create</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table :data="list" border stripe class="w100" size="mini" v-loading="loading.list">
          <el-table-column label="ruleId" prop="id" width="60px"></el-table-column>
          <el-table-column label="ruleName" prop="name"></el-table-column>
          <el-table-column
            label="priority"
            prop="priority"
            width="80px"
            align="center"
          ></el-table-column>
          <el-table-column
            label="createTime"
            prop="createTime"
            width="140px"
            align="center"
          ></el-table-column>
          <el-table-column
            label="updateTime"
            prop="updateTime"
            width="140px"
            align="center"
          ></el-table-column>
          <el-table-column
            label="closeOfferCount"
            prop="closeCount"
            width="130px"
          ></el-table-column>
          <el-table-column label="status" align="center" width="60px">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">on</span>
              <span v-else>Off</span>
            </template>
          </el-table-column>
          <el-table-column label="operate" width="210px" align="center">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                class="mr10"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="statusChange(scope.row.status, scope.row.id, scope.$index)"
              ></el-switch>
              <el-button type="primary" size="mini" @click="toEdit(scope.row)">Edit</el-button>
              <el-button type="danger" size="mini" @click="deletes(scope.row)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="align-c">
          <pagination
            class="block pagePanel"
            :pageTotal="total"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
            :pageSize="pageSize"
            :currentPage="currentPage"
          ></pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
  import CloseRuleListCon from '../controllers/product/CloseRuleList';
  export default {
    name: 'CloseRuleList',
    ...CloseRuleListCon,
  };
</script>
<style lang="scss" scoped></style>
