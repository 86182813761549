import {
  fetchCloseRulePage,
  handleUpdateCloseRuleStatus,
  handleDeleteCloseRule,
} from 'api/product/closeRule.js';
import Pagination from '@/components/pagination';
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      filter: {},
      total: null,
      pageSize: 10,
      loading: {
        list: false,
      },
      currentPage: 1,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList(curPage) {
      if (curPage) {
        this.currentPage = curPage;
      }
      let param = {
        ...this.filter,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.loading.list = true;
      fetchCloseRulePage(param)
        .then((res) => {
          if (res.code === 200) {
            this.list = res.result;
            this.total = res.total;
          } else {
            this.$message.error(res.message);
          }
          this.loading.list = false;
        })
        .catch((e) => {
          this.$message.error(e.message);
          this.loading.list = false;
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    // 跳转编辑
    toEdit(row) {
      this.$router.push({
        path: '/offer/close/rule/edit',
        query: {
          id: row.id,
        },
      });
    },
    // 更改状态
    statusChange(status, id, index) {
      handleUpdateCloseRuleStatus({ id: id, status: status }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: '状态更新成功！',
            type: 'success',
          });
        } else {
          this.list[index].status = !status;
          this.$message.error(res.message);
        }
      });
    },
    deletes(row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'maxWidthx100',
      })
        .then(() => {
          handleDeleteCloseRule(row.id).then((res) => {
            if (res.code !== 200 && res.success == false) {
              this.$message.error(res.message);
            } else {
              this.getList();
              this.$message({
                type: 'success',
                message: '删除成功!',
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 跳转添加
    add() {
      this.$router.push({
        path: '/offer/close/rule/add',
      });
    },
  },
};
